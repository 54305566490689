
/*--- Media Query ---*/
@mixin responsive($media) {
	@if $media == xl {
		@media only screen and (max-width: $xl) { @content; }
	}

	@else if $media == lg {
		@media only screen and (max-width: $lg) { @content; }
	}

	@else if $media == md {
		@media only screen and (max-width: $md) { @content; }
	}

	@else if $media == sm {
		@media only screen and (max-width: $sm) { @content; }
	}
	
	@else if $media == xs {
		@media only screen and (max-width: $xs) { @content; }
	}
}