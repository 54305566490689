@import 'assets/scss/variables';
@import 'assets/scss/mixins';

@import '~bootstrap/scss/bootstrap.scss';


/*   Tour Now  Page  */

.tour-now-container {
  .container {
    max-width: 1440px;
    width: 100%;
    padding: 0 3rem;
    margin: 0 auto;
  }

  .tour-now-button {
    border: 1px solid black;
    border-radius: .2rem;
    padding: 6px 16px;
    background-color: white;
    outline: none;
    font-weight: 500;
  }

  h1 {
    font-size: 40px;
    font-weight: 500;
    line-height: normal;
    color: black;
  }

  header {
    border-bottom: 1px solid silver;
    position: relative;
    background-color: white;

    div.header {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1.5rem;
      height: 80px;
      background-color: white;
      z-index: 50;

      .logo-img {
        flex-shrink: 0;
      }
  
      .nav-items {
        display: flex;
        align-items: center;
        gap: 2rem;
        font-weight: 500;
        color: black;
      }

      .styled-button {
        display: none !important;
      }

    }

    .mobile-menu {
      position: absolute;
      top: 71px;
      left: 0;
      width: 100%;
      background-color: #f4f4f4;
      z-index: 20;
      animation: slideDown .2s ease-in-out forwards;
      
      .nav-items {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0px;
        color: black;
        box-shadow: 1px 3px 10px 1px #dedede;

        .nav-item {
          border-bottom: 1px solid #d9dadd;
          padding: .9rem 1.5rem;
          width: 100%;
          font-weight: 500;
          text-transform: uppercase;
        }
      }
    }

    .hide-menu,
    .show-menu {
      display: none;
    }
  }

  .styled-button {
    cursor: pointer;
  }

  .tour-now-poster {
    display: grid;
    grid-template-columns: repeat(10, minmax(0, 1fr));
    gap: 2rem;

    .poster-content {
      grid-column: 1 / 6;

      h1 {
        font-size: 64px;
        line-height: 4.5rem;
        color: black;
        max-width: 500px;
        width: 100%;
        font-weight: 500;
        margin-top: 10px;
      }

      p {
        max-width: 450px;
        width: 100%;
        margin: 1rem 0;
        color: #393C47;
      }

      .nternow-icon-box {
        font-size: 15px;
        margin: 1rem 0;
        color: #393C47;

        .nternow-icon {
          height: 25px;
        }
      }

      .button-container {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin: 35px 0 0;

        .explore-btn {
          font-size: 15px;

          b {
            font-size: 20px;
          }
        }
      }
    }

    .poster-img {
      grid-column: 6 / 11;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
    }
  }
  
  .what-you-get {
    padding-top: 9rem;
    padding-bottom: 4rem;
    color: black;

    .card-container {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      gap: 1.6rem;
      margin-top: 3.5rem;

      .card {
        border: 1px solid #e1e1e1;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: .5rem;
        padding: 1.6rem;

        .card-icon {
          height: 50px;
          width: 50px;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .icon {
            height: 28px;
            width: 28px;
          }
        }

        h4 {
          text-align: center;
          color: black;
          font-weight: 500;
        }

        p {
          text-align: center;
          color: #494c55;
        }
      }
    }
  }

  .react-select-container {

    .react-select__control {
      // height: 42px;
      min-width: 170px;
      border-radius: 5px;

      .react-select__value-container {
        padding: 8px 10px;
        text-align: center;

        .react-select__single-value {
          font-weight: 500;
        }
      }
      
      .react-select__indicator {
        color: black;
      }

      
    }

    .react-select__control:hover {
      border: 1px solid black;
    }
    
    .react-select__control:focus {
      box-shadow: none !important;
    }
  }

  .filter-box {
    // border-bottom: 1px solid rgb(231, 231, 231);
    h5 {
      font-weight: 500;
    }
  }

  .location-card {
    flex-shrink: 0;
    border: 1px solid rgb(224, 224, 224);
    border-radius: 1rem;
    overflow: hidden;
    .location-img {
      width: 100%;
    }

    .location-content {
      padding: .85rem .7rem;
      h4, p {
        color: #121212;
        margin: 0;
      }

      h4 {
        font-size: 20px;
        font-weight: 500;
      }

      p {
        font-size: 14px;
      }

     .text-icon { 
      margin: 8px 0;
        span {
          color: #565658;
          font-size: 14px;
        }
      }

      .button-container {
        display: flex;
        flex-direction: column;
        gap: .7rem;

      }
    }
  }

  .map-box {
    display: flex;
    border: 1px solid silver;
    border-radius: 6px;
    padding: 3px;

    .switch-tab {
      cursor: pointer;
      padding: 4px 25px;
      background-color: white;
      border-radius: 6px;
      color: silver;
    }
  }

  .props-spinner-lg {
    height: 30px;
    width: 30px;
  }
  
  .props-spinner {
    display: none;
    position: absolute;
    top: 25%;
    right: 15%;

  }

  .property-collection {
    padding-top: 5rem;

    .collection-header {
      display: grid;
      grid-template-columns: repeat(12, minmax(0, 1fr));
      gap: 1.5rem;
      margin-top: 65px;

      .filter-header {
        grid-column: 1 / 4;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
          font-weight: 500;
          color: black;
        }

        p {
          cursor: pointer;
          user-select: none;
        }
      }

      .map-header {
        grid-column: 4 / 13;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .map-header.expanded {
        grid-column: 1 / 13;
      }
    }

    .collection-container{
      display: grid;
      grid-template-columns: repeat(12, minmax(0, 1fr));
      gap: 1.5rem;
      padding-bottom: 4rem;
      color: black;
      height: calc(100dvh - 60px);
      min-height: 550px;
      margin-bottom: 1rem;

      .filter-section {
        grid-column: 1 / 4;
        height: 100%;
        overflow-y: scroll;
      }

      .map-section {
        grid-column: 4 / 10;

        .map-box {
          display: none;
        }

        .google-maps-container {
          position: relative;
          height: 100%;
          overflow-y: scroll;
          border-radius: 1rem;

          .map-card {
            gap: 0.5rem;
            background-color: white;
            border-radius: 10px;
            overflow: hidden;
            cursor: pointer;
            z-index: 100;
    
            p {
              flex-shrink: 0;
              color: white;
              font-size: 12px;
              margin: 0;
              font-weight: 500;
            }
            
            .card-img-container {
              width: 50%;
              padding: 0 10px 0 10px;
            }
    
            .map-card-content {
              // height: 100%;
              width: 50%;
              padding: .8rem .6rem;
    
              .close-icon {
                position: absolute;
                top: 0;
                right: 0;
                font-weight: 600;
                font-size: 13px;
                height: 20px;
                width: 20px;
                cursor: pointer;
                border-radius: 100%;
                background-color: rgba(255, 255, 255, .8);
                z-index: 50;
              }

              h4 {
                font-size: 20px;
              }
    
              p {
                color: black;
                font-weight: normal;
              }
    
              .room-info {
    
                .info-box {
                  padding: 0 10px;
                  border-left: 1px solid #cfcfcf;
                }
                
                .info-box:first-child {
                    padding: 0 10px 0 0;
                    border-left: none;
                }
              }
              
            }
          }
        }

        .listing-wrapper {
          max-height: calc(100dvh - 100px);
          overflow-y: scroll;
        }
        
        .listing-container {
          display: grid;
          gap: 1.5rem;
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        .listing-container.expanded {
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }
      }

      .map-section.expanded {
        grid-column: 1 / 10;
      }

      .places-section {
        grid-column: 10 / 13;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        height: 100%;
        overflow-y: scroll;
      }

      .filter-tabs {
        display: flex;
        align-items: center;
        gap: .6rem;
        flex-wrap: wrap;

        &::-webkit-scrollbar {
          height: 0;
          width: 0;
        }

        .tabs {
          flex-shrink: 0;
          background-color: #F4F5F7;
          padding: .25rem 1rem;
          border-radius: 2rem;
          font-size: 14px;
          color: black;
          min-width: 55px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
}

.edit-icon {
  opacity: .5;
}

@keyframes popOut {
  0% {
    opacity: 0;
    transform: scale(.8);
  }
  60% {
    opacity: 1;
    transform: scale(1.12);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@include responsive(xl) {
  .tour-now-container {
    .tour-now-poster {
      .poster-content {
        .content-box {
          max-width: 340px;
          width: 100%;

          h1 {
            font-size: 3rem;
            line-height: 57px;
            margin-top: 10px;
          }
        }
      }
    }

    .property-collection {

      .collection-header {

        .filter-header {
          grid-column: 1 / 5;
        }
  
        .map-header {
          grid-column: 5 / 13;
        }
      }

      .collection-container{

        .filter-section {
          grid-column: 1 / 5;

          .filter-tabs {

            .tabs {
              padding: .15rem .8rem;
              font-size: 12px;
              min-width: 40px;
              border-radius: .9rem;
            }
          }
        }
  
        .map-section {
          grid-column: 5 / 13;
        }

        .map-section.expanded {
          grid-column: 1 / 13;
        }
  
        .places-section {
          display: none;
        }
      }
    }
  }
}

@include responsive(lg) {
  .tour-now-container {

    .tour-now-button {
      font-size: 12px;
      padding: 4px 14px;
    }

    header {
      div.header {
        height: 70px;

        .logo-img {
          max-width: 200px;
        }

        .nav-items {
          font-size: 13px;
          gap: 1rem;
          font-weight: normal;
        }
      }
    }

    .tour-now-poster {
      margin-top: 30px !important;

      .poster-content{
        grid-column: 1 / 6;

        .tour-now-img {
          max-width: 200px;
          width: 100%;
        }

        .content-box {
          max-width: 260px;

          h1 {
            font-size: 2.2rem;
            line-height: 45px;
          }
        }

        .button-container {
          margin: 25px 0 0;
          gap: .6rem;

          .explore-btn {
            font-size: 10px;
  
            b {
              font-size: 15px;
            }
          }
        }
      }
      
      .poster-img {
        grid-column: 6 / 11;
      }
    }

    .what-you-get {
      padding-top: 7rem;
      padding-bottom: 2rem;

      .card-container {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        max-width: 800px;
        width: 100%;
        margin: 3.5rem auto 0;
      }
    }

    .props-spinner-lg {
      display: none;
    }
    
    .props-spinner {
      display: block;
      border-width: 2px;
    }

    .property-collection {
      padding-top: 3rem;

      .collection-header {
        grid-template-columns: auto;
        margin-top: 45px;

        .filter-header {
          grid-column: auto;
        }
  
        .map-header {
          grid-column: auto;

          .map-box {
            display: none !important;
          }

          .filter-box {
            width: 100%;
            
             .styled-button{
              flex: 1;
            }

            .react-select-container {
              flex: 1;

              .react-select__control {
                min-width: auto;
                .react-select__value-container {
          
                  .react-select__single-value {
                    font-size: 13px;
                  }
                }
              }
            }
          }
        }
        
      }

      .collection-container{
        grid-template-columns: auto;
        height: auto;
        min-height: auto;
  
        .filter-section {
          grid-column: auto;
          height: auto;
        }
  
        .map-section {
          grid-column: auto;

          .map-box {
            display: flex;

            .switch-tab {
              flex: 1;
              text-align: center;
            }
          }

          .google-maps-container {
            height: 70dvh;

            .map-card {
              bottom: 50%;
              flex-direction: column;
              width: 260px;
              max-height: unset;
              height: unset;
              gap: 0;
    
              .card-img-container {
                width: 100%;
                height: auto;
                overflow: hidden;
    
                .map-card-img {
                  flex-shrink: 0;
                  height: unset;
                  width: 100%;
                  margin: 10px;
                }
              }
    
              .map-card-content {
                height: unset;
                width: 100%;
                padding: .8rem .7rem 1.3rem;
    
                // .close-icon {
                //   top: 1.5%;
                //   right: 3.5%;
                // }
    
                .room-info {
                  margin: 5px 0;
                }
              }
            }
          }

          .listing-wrapper {
            max-height: 80dvh;
            overflow-y: scroll;
          }

          .listing-container {
            display: grid;
            gap: 1.5rem;
            grid-template-columns: repeat(2, minmax(0, 1fr));
          }

          .listing-container.expanded {
            grid-template-columns: repeat(2, minmax(0, 1fr));
          }
        }
  
        .places-section {
          display: none;
        }
      }
    }
  }
}

@include responsive(md) {
  .tour-now-container {

    .heading-component {
      font-size: 1.7rem;
      line-height: 2.5rem;
    }

    .container {
      padding: 0 1.5rem;
    }

    .styled-button {

      img {
        width: 14px;
      }

      p {
        font-size: 13px
      }
    }

    header {
      div.header {
        height: 70px;

        .logo-img {
          max-width: 180px;
        }

        .nav-items {
          display: none;
        }

        .tour-now-button {
          display: none;
        }

        .styled-button {
          display: flex !important;
        }
      }

      .show-menu {
        display: block;
      }

      .hide-menu {
        display: none;
      }
    }

    .tour-now-poster {
      margin-top: 3rem !important;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 3.5rem;

      .poster-content{
        grid-column: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        .tour-now-img {
          max-width: 150px;
          width: 100%;
        }

        .content-box {
          margin-top: 1.2rem;
          max-width: 100%;

          h1 {
            // font-size: 2rem;
            line-height: 38px;
            text-align: center; 
            max-width: 240px;  
            width: 100%;
            margin: 0 auto;         
          }

          p {
            text-align: center;
            max-width: 300px;
            width: 100%;
            margin: 1rem auto 0;
          }
        }

        .nternow-icon-box {
          font-size: 14px;
        }

        .button-container {
          margin: 14px 0 0;
          gap: .6rem;
          flex-direction: column;
          width: 100%;
          gap: .9rem;

          .scroll-link {
            width: 100%;
          }

          .explore-btn {
            font-size: 10px;
  
            b {
              font-size: 15px;
            }
          }

          button {
            width: 100%;
            padding: 7px 14px;
          }
        }
      }
      
      .poster-img {
        grid-column: auto;
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }

    .what-you-get {
      padding-top: 3rem;


      .card-container {
        grid-template-columns: auto;
        max-width: 800px;
        width: 100%;
        margin: 3.5rem auto 0;
      }
    }

    .property-collection {
      padding-top: 5rem;

      .collection-container{
  
        .map-section {

          .listing-container {
            grid-template-columns: repeat(1, minmax(0, 1fr));
          }

          .listing-container.expanded {
            grid-template-columns: repeat(1, minmax(0, 1fr));
          }
        }
      }

      .map-container {
  
        .map-tab {
          background-color: #3E864A;
          padding: 3px 10px;
          border-radius: 3px;
          width: max-content;
        }
      }
    }

    
  }
}

// Accordion settings

.accordion-button:not(.collapsed) {
  background-color: white;
  box-shadow: none;
}

.accordion-button.collapsed {
  // border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  // border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
  box-shadow: none;
}

.accordion-item {
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #e0dede;
}

.accordion-button,
.accordion-body {
  padding-left: 0;
  padding-right: 0;
}

.accordion {
  border-radius: 0px !important;
  --bs-accordion-border-radius: 0px;
}

// Google Maps Customizations

// button.gm-ui-hover-effect {
//   display: none !important;
// }

.location-marker {
  width: max-content;
  color: white;
  border-radius: 4px;
  cursor: pointer;

  .home-icon {
    width: 14px;
    height: 14px;
  }

  p {
    font-size: 12px;
  }
}

.gm-style-iw-d,
.gm-style .gm-style-iw-c {
  padding: 0 !important;
}

.gm-style-iw-d {
  &::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }
}

.image-gallery-custom {
  .image-gallery-icon {
    & .image-gallery-svg {
      width: 35px;
      height: 35px;
    }
  }
}

.skeleton {
  min-width: 100%;
  width: 250px;
  height: 200px; /* Adjust height based on your image gallery */
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.hidden {
  display: none;
}