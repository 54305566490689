.container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  background: rgba(255, 255, 255, .8 );
  backdrop-filter: blur(15px);
  z-index: 99999;
}